/** 1. Imports **/
import React from 'react'
import styled, { keyframes } from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

import { MetaTag } from '../components/metaTag'
import { useSiteMetadata } from '../providers/hooks/useSiteMetadata'

/** 2. Types **/
type Props = {
  className?: string
  title: string
}

/** 3. Base component **/
const Component = ({ className, title }: Props) => (
  <>
    <MetaTag />
    <div className={className}>
      <div>
        <StaticImage
          src={'../../static/logo.png'}
          alt={'logo'}
          placeholder={'tracedSVG'}
          quality={100}
        />
        <h1>{title}</h1>
      </div>
      <p>We solve, on the WEB</p>
    </div>
  </>
)

/** 4. Styled component **/
const glitch = keyframes`
  20% {
    transform: scale(1);
  }
  20.15% {
    transform: skew(10deg, 10deg);
  }
  20.3% {
    transform: scale(1);
  }

  80% {
    transform: scale(1);
  }

  80.2% {
    transform: scale(1.3) skew(10deg, 20deg);
  }
  80.4% {
    transform: scale(0.7) skew(-20deg, -20deg);
  }
  80.6% {
    transform: scale(1.5) skew(5deg, 30deg);
  }
  81% {
    transform: scale(1);
  }
`

const StyledComponent = styled(Component)`
  display: grid;
  justify-items: center;

  > div {
    > div.gatsby-image-wrapper {
      width: 200px;
      height: 200px;

      animation: ${glitch} 10s;
      animation-iteration-count: infinite;

      z-index: 999;
    }

    > h1 {
      margin: 8px 0;
      font-size: 6rem;
      font-weight: ${({ theme }) => theme.font.weight['900']};
      text-align: center;
      color: hsl(0, 0%, 16%);
    }
  }

  > p {
    margin-top: 24px;
    font-size: 2rem;
    color: hsl(0, 0%, 40%);
  }
`

/** 5. Container **/
const IndexPage = (props: any) => {
  const { siteMetadata } = useSiteMetadata()

  return <StyledComponent {...props} title={siteMetadata.title} />
}
export default IndexPage
